'use client';

import {
  MantineProvider,
  createTheme,
  darken,
  defaultVariantColorsResolver,
  parseThemeColor,
  rem,
  rgba,
} from '@mantine/core';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from './theme-provider';

import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import localforage from 'localforage';

import React from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental';
import { AnimatePresence } from 'framer-motion';
import { Provider as StateProvider } from 'jotai';
import { useAtomsDebugValue } from 'jotai-devtools';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useHydrateAtoms } from 'jotai/utils';
import { TRPCReactProvider } from '../trpc/trpc-react-provider';
import { getQueryClient } from './reactQueryProvider';

const theme = createTheme({
  primaryColor: 'medpods',
  fontFamily: 'muli, sans-serif',
  colors: {
    medpods: [
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
      '#317973',
    ],
  },
  headings: { fontFamily: 'muli, sans-serif' },
  variantColorResolver: (input) => {
    const defaultResolvedColors = defaultVariantColorsResolver(input);
    const parsedColor = parseThemeColor({
      color: input.color || input.theme.primaryColor,
      theme: input.theme,
    });

    if (input.variant === 'filled') {
      return {
        background: rgba(parsedColor.value, 0.1),
        hover: rgba(parsedColor.value, 0.15),
        border: `${rem(1)} solid ${parsedColor.value}`,
        color: darken(parsedColor.value, 0.1),
      };
    }

    return defaultResolvedColors;
  },
});

export const metadata = {
  title: 'Medpods Telehealth',
  description: 'Your number #1 Tele health provider.',
};

// If loading a variable font, you don't need to specify the font weight
const HydrateAtoms = ({ children, queryClient }) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return children;
};

const DebugAtoms = () => {
  useAtomsDebugValue();
  return null;
};

const asyncStoragePersister = createAsyncStoragePersister({
  storage: localforage,
  // TODO - here goes logged in client encryption - example https://gist.github.com/mfcodeworks/6a54a790e60717c84a77b4231a2db490#file-encrypted-forage-js-L26
  serialize: (data) => JSON.stringify(data),
  deserialize: (data) => JSON.parse(data),
});

export function Providers(props: { children: React.ReactNode }) {
  // NOTE: Avoid useState when initializing the query client if you don't
  //       have a suspense boundary between this and the code that may
  //       suspend because React will throw away the client on the initial
  //       render if it suspends and there is no boundary
  const queryClient = getQueryClient();

  return (
    <MantineProvider
      theme={theme}
      cssVariablesSelector="html"
      forceColorScheme="light"
    >
      <ThemeProvider
        attribute="class"
        defaultTheme="light"
        forcedTheme="light"
        enableSystem={false}
      >
        <AnimatePresence mode="wait">
          <QueryClientProvider client={queryClient}>
            <ReactQueryStreamedHydration queryClient={queryClient}>
              <TRPCReactProvider queryClient={queryClient}>
                {/* TODO: Persistent caching for query optimisation PoC */}
                {/* <PersistQueryClientProvider
                  client={queryClient}
                  persistOptions={{
                    persister: asyncStoragePersister,
                    maxAge: Infinity,
                    // maxAge: 1000 * 60 * 60 * 24, // 24 hours
                    buster: '',
                    hydrateOptions: undefined,
                    dehydrateOptions: undefined,
                  }}
                > */}
                <StateProvider>
                  {process.env.NODE_ENV !== 'production' && (
                    <>
                      <ReactQueryDevtools position="bottom" />
                      {/* <DevTools position="top-right" />
                      <DebugAtoms /> */}
                    </>
                  )}

                  <HydrateAtoms queryClient={queryClient}>
                    {props.children}
                  </HydrateAtoms>
                </StateProvider>
                {/* </PersistQueryClientProvider> */}
              </TRPCReactProvider>
            </ReactQueryStreamedHydration>
          </QueryClientProvider>
        </AnimatePresence>
      </ThemeProvider>
    </MantineProvider>
  );
}
