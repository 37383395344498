'use client';

import { type QueryClient } from '@tanstack/react-query';
import { httpBatchLink, loggerLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server';
import SuperJSON from 'superjson';
import { AppRouter } from '../server/api/root';

import { useCookies } from 'react-cookie';

export const api = createTRPCReact<AppRouter>();

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;

export function TRPCReactProvider(props: {
  queryClient: QueryClient;
  children: React.ReactNode;
}) {
  const queryClient = props.queryClient;
  const [cookie] = useCookies();

  const trpcClient = api.createClient({
    links: [
      loggerLink({
        enabled: (op) =>
          process.env.NODE_ENV === 'development' ||
          (op.direction === 'down' && op.result instanceof Error),
      }),
      // unstable_httpBatchStreamLink
      httpBatchLink({
        transformer: SuperJSON,
        //  'https://apis.medpods.com.au/dev/api/booking',

        url: process.env['NEXT_PUBLIC_RESOURCE_TRPC_URL'] ?? '',
        // getBaseUrl() + '/api/booking',
        fetch: (url, options) => {
          console.log(options);
          return fetch(url, {
            ...options,
            // mode: 'no-cors',
            // credentials: 'include',
          });
        },
        headers: async (opts) => {
          const headers = new Headers(cookie);
          console.log({ cookie });
          headers.set('x-trpc-source', 'nextjs-react');
          const setCookie = headers
            .get('set-cookie')
            ?.split(',')
            .map((cookie) => cookie.trim())
            .at(0);
          const cookies: string[] = headers
            .getSetCookie()
            .flatMap((value) => value.split(','))
            .map((cookie) => cookie.trim());

          const cors = {
            'Access-Control-Allow-Origin': 'https://telehealth.medpods.com.au',
            'Access-Control-Allow-Methods':
              'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': '*',
          };

          return {
            'x-cookie': setCookie,
            'x-patient': JSON.stringify(cookie['patient']),
            'x-patient-select': JSON.stringify(cookie['patientSelect']),
            'x-pharmacy-patient': JSON.stringify(cookie['pharmacyPatient']),
            'x-appointment': JSON.stringify(cookie['appointment']),
            'x-trpc-source': 'nextjs-react',
          };
        },
      }),
    ],
  });
  return (
    // <QueryClientProvider client={queryClient}>
    <api.Provider client={trpcClient} queryClient={queryClient}>
      {props.children}
    </api.Provider>
    // </QueryClientProvider>
  );
}

function getBaseUrl() {
  if (typeof window !== 'undefined') return window.location.origin;
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`;
  return `http://localhost:${process.env.PORT ?? 3000}`;
}
